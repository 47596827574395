
import Vue, { PropType } from 'vue';
import 'vue-slider-component/theme/default.css';

import {
  FilterInput,
  MakeSearchFilters_makeSearch_facets,
  MakeSearchFilters_makeSearch_facets_range,
} from '~/apollo-api/types';

import { throttle } from '~/modules/common/throttle';

import InputUi from '~/components/common/input.vue';

import { KopsInRub } from '~/filters';
import e from 'express';

const SLIDER_OPTIONS = {
  minRange: 1,
  enableCross: false,
  silent: true,
  dotSize: '16',
  dotStyle: {
    background: '#fff',
    border: '2px solid black',
    'box-shadow': 'none',
    width: '100%',
    height: '100%',
    'border-radius': '100%',
  },
  railStyle: { height: '2px', background: '#F4F5F5' },
  processStyle: { background: '#27282A' },
  labelStyle: { display: 'none' },
  tooltipStyle: { display: 'none' },
};

const THROTTLE_TIME = 800;

enum EInputType {
  MinValue = 'minValue',
  MaxValue = 'maxValue',
}

const INITIAL_PRICE_VALUE = '';

export default Vue.extend({
  components: {
    InputUi,
  },
  props: {
    facet: {
      type: Object as PropType<MakeSearchFilters_makeSearch_facets>,
      required: true,
    },
    currentQueryInput: {
      type: Object as PropType<FilterInput | undefined>,
    },
  },
  data() {
    return {
      EInputType: EInputType,
      options: SLIDER_OPTIONS,
      minValue: INITIAL_PRICE_VALUE,
      maxValue: INITIAL_PRICE_VALUE,
      // eslint-disable-next-line @typescript-eslint/ban-types
      throttledChange: undefined as Function | undefined,
    };
  },
  computed: {
    formattedRange(): Omit<MakeSearchFilters_makeSearch_facets_range, '__typename'> {
      if (!this.facet.range) {
        return { min: 0, max: 0 };
      }

      const { min = 0, max = 0 } = this.facet.range;
      return {
        min: Number(this.formatPrice(min)),
        max: Number(this.formatPrice(max)),
      };
    },
    isEqual(): boolean {
      return this.formattedRange.min === this.formattedRange.max;
    },
  },
  watch: {
    formattedRange: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        if (oldVal && (val.min !== oldVal.min || val.max !== oldVal.max)) this.validatePrices();
      },
    },
  },
  created() {
    this.setInitialValue();
    this.throttledChange = throttle(this.onChange, THROTTLE_TIME);
  },
  methods: {
    validatePrices() {
      if (this.minValue === INITIAL_PRICE_VALUE || this.maxValue === INITIAL_PRICE_VALUE) {
        return;
      }

      if (+this.minValue < this.ceilMin(this.formattedRange.min)) {
        // проверка на случай, если при более углубленной фильтрации изменились границы цен
        this.minValue = String(this.ceilMin(this.formattedRange.min));
      }
      if (+this.maxValue >= this.formattedRange.max) {
        this.maxValue = String(this.ceilMax(this.formattedRange.max));
      }
    },
    ceilMin(value: number) {
      return Math.floor(value);
    },
    ceilMax(value: number) {
      return Math.ceil(value);
    },
    setInitialValue(): void {
      if (this.currentQueryInput) {
        const { min, max } = this.currentQueryInput.range!;
        this.minValue = min ? this.formatPrice(min) : '0';
        this.maxValue = max ? this.formatPrice(max) : '0';
      } else {
        this.minValue = String(this.ceilMin(this.formattedRange.min));
        this.maxValue = String(this.ceilMax(this.formattedRange.max));
      }
    },
    updateQuery(): void {
      const range = {
        min: Number(this.minValue) * KopsInRub,
        max: Number(this.maxValue) * KopsInRub,
      };

      if (this.currentQueryInput) {
        const { min, max } = this.currentQueryInput.range!;
        if (min !== range.min || max !== range.max) {
          this.$emit('update-query-input', { range });
        }
      } else {
        this.$emit('update-query-input', { range });
      }
    },
    onChange([minPrice, maxPrice]: [number, number]) {
      this.minValue = String(minPrice);
      this.maxValue = String(maxPrice);

      this.updateQuery();
    },
    onEnter(event): void {
      event.target.blur();
    },
    onBlur(event, type: EInputType): void {
      if (this.isEqual) {
        return;
      }

      const { min, max } = this.formattedRange;

      let newValueString = event.target.value;
      if (newValueString === '') {
        // на случай если все поле ввода отчистили
        if (type === EInputType.MaxValue) {
          newValueString = String(this.ceilMax(max));
          this.maxValue = newValueString;
        } else {
          newValueString = String(this.ceilMin(min));
          this.minValue = newValueString;
        }
      }
      const newValue = Number(newValueString);

      if (newValue < min) {
        this[type] = String(this.ceilMin(min));
      } else if (newValue > max) {
        this[type] = String(this.ceilMax(max));
      }

      if (Number(this.minValue) <= Number(this.maxValue)) {
        this.updateQuery();
      } else {
        // на случай, если минимальное значение переходит за границу максимального и наоборот
        if (type === EInputType.MaxValue) {
          this.minValue = this.maxValue;
        } else {
          this.maxValue = this.minValue;
        }
        this.updateQuery();
      }
    },
    formatPrice(kops: number): string {
      const rub = kops / KopsInRub;
      return rub.toFixed(0);
    },
  },
});
