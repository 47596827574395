<template lang="pug">
.ds-signup
  template(v-if="state === states.Password")
    first-password-ds(:is-verify-phone="isVerifyPhone")
  template(v-if="state === states.Code")
    verify-phone-ds(:is-verify-phone="isVerifyPhone")
  template(v-if="state === states.Phone")
    .signup-title Зарегистрируйтесь и начните совершать выгодные покупки

    .signup-form(@keyup.enter="proceed")
      .input-login.input-row
        labeled-input-ds(
          mask="+7 ### ###-##-##"
          v-model="phone"
          :is-error="!!error.length"
          placeholder='+7'
          type="tel"
          label="Номер телефона"
          :transform="phoneTransform"
        )
          .action(
            slot="after"
            v-if="isValidatePhone")
            icon-ui.check(name="status/check_line")
        labeled-input-ds(
          v-show="false"
          v-model="phone"
          )
      label.error(v-if="error.length" v-html="error")
      .form-actions.input-row
        button-ui(
          :disabled="!canProceed || isProccessing"
          @click="proceed"
        ) {{buttonMessage}}
          spinner-ds(
            slot="before"
            v-if="isProccessing"
            :size="20"
          )
    .signup-footer
      .warning Нажимая кнопку «Получить код», вы соглашаетесь на обработку персональных данных в соответствии с #[link-ui(:to="{name:'privacy-policy'}") Условиями]
      button-ui.to-restore(v-if='isShowRestore' @click.stop="$emit('change-tab', 'restore')") Восстановить пароль

</template>

<script>
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import SpinnerDs from '~/components/spinner';
import ButtonUi from '~/components/common/button';
import LabeledInputDs from '~/components/labeled-input';

import VerifyPhoneDs from './verify-phone';
import FirstPasswordDs from './first-password';

import { signUp } from '~/apollo-api/signUp';
import { SignUpState } from '~/store/user';
import { phoneTransform } from '~/utils/phoneTransform';

import { StaticDataMixin } from '~/mixins/staticData';
import { getSkuIdsForMerge, add } from '~/apollo-api/favorite';

import Cookies from 'js-cookie';

export default {
  components: {
    LabeledInputDs,
    IconUi,
    LinkUi,
    ButtonUi,
    VerifyPhoneDs,
    FirstPasswordDs,
    SpinnerDs,
  },
  mixins: [StaticDataMixin],
  inject: ['initPhone'],
  props: {
    isVerifyPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: '',
      isProccessing: false,
      now: 0,
      intervalInstance: 0,
      intervalTime: 1000, // each second
      error: '',
      codeSentCoolDown: 60000,
      states: SignUpState,
      phoneTransform,
      isShowRestore: false,
      btnDisabled: false,
      skuIds: [],
    };
  },
  computed: {
    canProceed() {
      return this.isValidatePhone && !this.btnDisabled && this.now - this.lastCodeSentAt > this.codeSentCoolDown;
    },
    state() {
      return this.$store.state.user.registration.state;
    },
    lastCodeSentAt() {
      return this.$store.state.user.registration.lastCodeSentAt;
    },
    isValidatePhone() {
      return this.truePhone.length === 10;
    },
    truePhone() {
      return this.phone.replace('+7', '').replace(/[^0-9]/g, '');
    },
    cutPhone() {
      return `${this.truePhone.slice(0, 3)}***${this.truePhone.slice(-3)}`;
    },
    buttonMessage() {
      if (this.lastCodeSentAt) {
        const timeAfterLastSend = this.now - this.lastCodeSentAt;
        if (timeAfterLastSend <= this.codeSentCoolDown - 1000) {
          return `Подождите ${Math.floor((this.codeSentCoolDown - timeAfterLastSend) / 1000)} с`;
        }
      }
      return 'Получить код в SMS';
    },
  },
  watch: {
    phone() {
      this.error = '';
    },
  },
  mounted() {
    this.$root.$on('disabled', (isOtherError) => (this.btnDisabled = isOtherError));
  },
  // eslint-disable-next-line no-dupe-keys
  async mounted() {
    this.updateNow();
    this.intervalInstance = setInterval(this.updateNow.bind(this), 1000);

    if (this.initPhone?.length) {
      this.phone = this.initPhone;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalInstance);
  },
  methods: {
    updateNow() {
      this.now = Math.ceil(new Date().getTime());
    },
    async proceed() {
      if (!this.canProceed) {
        return;
      }

      try {
        this.isProccessing = true;
        this.isShowRestore = false;
        const tokens = Cookies.get('auth');
        if (tokens) {
          this.$store.dispatch('user/logOut');
        }
        const { accessToken, refreshToken, customer } = await signUp(this.truePhone);
        this.$store.dispatch('user/setCustomer', customer);
        this.$store.dispatch('user/setRegistrationToken', {
          accessToken,
          refreshToken,
        });
        this.$store.dispatch('user/setCodeRequestTime', new Date().getTime());
        this.$store.dispatch('user/setRegistrationEmailPhone', { phone: this.phone });
        this.$gtm.push(this.$dataLayer.registrationRequestSms(this.cutPhone));
        this.$store.dispatch('user/setState', SignUpState.Code);
      } catch (error) {
        console.error(error);
        const errors = error.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        if (errors.includes('AccountAlreadyExistsException') || errors.includes('PhoneNumberExistsException')) {
          this.error = 'Этот номер уже зарегистрирован.<br>Попробуйте войти или восстановить пароль';
          this.isShowRestore = true;
        } else if (errors.includes('TimeoutNotExpiredException')) {
          this.error = 'Превышено количество попыток';
        } else {
          this.error = 'Что-то пошло не так. Повторите попытку';
        }
      } finally {
        await add(this.skuIds);
        this.isProccessing = false;
        this.$store.dispatch('user/incrementAttempt');
      }
    },
    async getSkuIds() {
      await getSkuIdsForMerge().then((data) => {
        this.skuIds = data.flatMap((sku) => sku.id);
      });
    },
  },
};
</script>

<style lang="stylus">
.ds-signup
  .signup-title
    Text(TitleS)
    padding-top 40px
    text-align center

  .signup-form
    .action
      padding 6px
      .check path
        fill Green(LT4)
    .input-row
      margin-top 24px

      label
        .ui-input
          margin-top 8px

      .ui-input
        Input(Outlined)

        +Media(Laptop, Desktop, DesktopMax)
          Input(Large)

        +Media(Mobile, Tablet)
          Input(Medium)

    .form-actions
      .ui-button
        Button(Large Primary)
        width 100%
        white-space nowrap
        .ds-spinner
          margin-right 10px
  .signup-footer
    .warning
      Text(CaptionM)
      color TextSecondary()
      text-align center
      margin-top 24px
      .ui-link
        Text(Link)
        text-decoration underline
    .to-restore
      Button()
      Text(BodyM Semibold)
      width 100%
      margin 32px auto 0
</style>
