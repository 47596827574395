import { mapState } from 'vuex';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
import Vue from 'vue';
import { dshopMain } from '~/apollo-api/dshopMain';
import { mainBanners } from '~/apollo-api/mainBanners';
import { DisplayType } from '~/apollo-api/types';

import { StaticDataMixin } from '../staticData';
import { getMainCategories } from '~/apollo-api/getMainCtegories';

export const MainStoreMixin = Vue.extend({
  mixins: [StaticDataMixin],
  computed: {
    ...mapState('checkout', ['city']),
    ...mapState('catalog', ['promoCategories']),
  },
  methods: {
    async getMainData(productsSize, isMobileOrTablet) {
      const banners = await mainBanners(isMobileOrTablet ? DisplayType.MOBILE : DisplayType.DESKTOP);
      const dshopMainData = await dshopMain(productsSize, isMobileOrTablet ? DisplayType.MOBILE : DisplayType.DESKTOP);
      Object.entries(dshopMainData).forEach(([name, value]) => {
        // eslint-disable-next-line
        // @ts-ignore
        if (name === 'cities') {
          value = value.filter((city) => city.name === CITY_WITH_PARTNER_DP);
        }
        this.$store.dispatch('main/updateItem', { name, value });
      });
      this.$store.dispatch('main/updateItem', { name: 'banners', value: banners });
    },
    async getMainCategories() {
      if (this.promoCategories.length) {
        return;
      }
      const categories = await getMainCategories();
      Object.entries(categories).forEach(([name, value]) => {
        this.$store.dispatch('catalog/updateItem', { name, value });
      });
    },
  },
});
