import { gql, client } from './index';

export const getMainCategories = async () => {
  //hack for apollo-types
  const newQuery = `
  query MainCategories {
    dshopMain {
      promoCategories {
        deepLink
        title
        subtitle
      }
      categories {
        id
        title
        image {
          high
          low
        }
        children {
          title
          id
          parent {
            id
            title
          }
          children {
            id
            title
            parent {
              id
              title
            }
          }
        }
      }
    }
  }`;

  const { data } = await client.query({
    query: gql(newQuery),
  });

  return { ...data.dshopMain };
};
