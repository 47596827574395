export const signupSuccessEvent = function () {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'sign_up',
    eventCategory: 'Personal account',
    eventAction: 'Sign up',
    nonInteraction: 'false',
    properties: {
      method: 'phone',
    },
  };
};

export const registrationRequestSms = function (number) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'registration_request_sms',
    eventCategory: 'Registration',
    eventAction: 'Request SMS',
    properties: {
      phone_part: number,
    },
  };
};
