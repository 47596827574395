import { DEFAULT_QUERY_INPUT } from '~/modules/search/constants';

export const state = () => ({
  searchCardList: {
    items: [],
  },
  searchQuery: DEFAULT_QUERY_INPUT,
});

export const getters = {
  cards(state) {
    return { ...state.searchCardList };
  },
  query(state) {
    return state.searchQuery;
  },
};

export const mutations = {
  setSearchCards(state, payload) {
    state.searchCardList = payload;
  },
  clearSearchCards(state) {
    state.searchCardList = {
      items: [],
    };
  },
  setSearchQuery(state, payload) {
    state.searchQuery = payload;
  },
  clearSearchQuery(state) {
    state.searchQuery = DEFAULT_QUERY_INPUT;
  },
};

export const actions = {
  setSearchCards({ commit }, payload) {
    commit('setSearchCards', payload);
  },
  clearSearchCards({ commit }) {
    commit('clearSearchCards');
  },
  setSearchQuery({ commit }, payload) {
    commit('setSearchQuery', payload);
  },
  clearSearchQuery({ commit }) {
    commit('clearSearchQuery');
  },
};
