import cookie from 'cookie';
import { orderSuccessEvent } from './event-order';
import { loginSuccessEvent } from './event-login';
import { signupSuccessEvent, registrationRequestSms } from './event-signup';
import { productOpenEvent } from './event-product';
import { addToCartEvent, setCatalogItemIndex, setCatalogItemListName } from './event-addtocart';
import { addToFavoriteEvent, setFavoriteItemIndex, setFavoriteItemListName } from './event-addtofavorite';
import { removeFromCartEvent } from './event-removefromcart';
import { removeFromFavoriteEvent } from './event-removefromfavorite';
import { selectProductEvent } from './event-selectproduct';
import { selectBannerEvent, viewBannerEvent } from './event-banner';
import { beginCheckoutEvent } from './event-begincheckout';
import { addShippingEvent } from './event-addshipping';
import { goToPaymentEvent } from './event-gotopayment';
import { listingEvent } from './event-listing';
import { cartOpenEvent } from './event-cart';
import { favoriteOpenEvent } from './event-favorite';

export default (context, inject) => {
  let userId = null;
  let innerHTML = `window.dataLayer=window.dataLayer||[];window.dataLayer.push({originalLocation:document.location.protocol+"//"+document.location.hostname+document.location.pathname+document.location.search});`;
  if (process.server) {
    userId = cookie.parse(context.req.headers.cookie || '')['userId'] || '';
  } else {
    const localUser = localStorage.getItem('vuex');
    if (localUser && localUser.user) {
      userId = JSON.parse(localUser).user?.customer?.id;
    }
  }

  if (userId) {
    innerHTML = `window.dataLayer=window.dataLayer||[];window.dataLayer.push({user_id:'${userId}',originalLocation:document.location.protocol+"//"+document.location.hostname+document.location.pathname+document.location.search});`;
  }

  const scriptId = 'gtm-dataLayer-init';
  const dataLayerInitScript = {
    hid: scriptId,
    innerHTML: innerHTML,
    type: 'text/javascript',
    charset: 'utf-8',
    async: true,
  };
  if (process.client) {
    let fired = false;

    window.addEventListener('DOMContentLoaded', () => {
      if (window && window.usedeskMessenger) {
        if (document.location.href.includes('product')) {
          window.usedeskMessenger.toggle(false);
        } else {
          window.usedeskMessenger.toggle(true);
        }
      }
      if (fired === false) {
        fired = true;
        context.app.$gtm.init('GTM-MF65JF8');
        context.app.head.script = context.app.head.script || [];
        context.app.head.script.unshift(dataLayerInitScript);
        context.app.head.__dangerouslyDisableSanitizersByTagID =
          context.app.head.__dangerouslyDisableSanitizersByTagID || {};
        context.app.head.__dangerouslyDisableSanitizersByTagID[scriptId] = ['innerHTML'];
      }
    });
  }

  if (process.client) {
    context.app.router.afterEach(() => {
      if (window && window.usedeskMessenger) {
        if (document.location.href.includes('product')) {
          window.usedeskMessenger.toggle(false);
        } else {
          window.usedeskMessenger.toggle(true);
        }
      }
      context.app.$gtm.push({
        user_id: context.app.store.state.user.customer.id || '',
        originalLocation:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      });
    });
  }

  context.$dataLayer = {
    orderSuccessEvent,
    loginSuccessEvent,
    signupSuccessEvent,
    productOpenEvent,
    addToCartEvent,
    setCatalogItemIndex,
    setCatalogItemListName,
    removeFromCartEvent,
    selectProductEvent,
    selectBannerEvent,
    viewBannerEvent,
    beginCheckoutEvent,
    addShippingEvent,
    goToPaymentEvent,
    listingEvent,
    cartOpenEvent,
    addToFavoriteEvent,
    setFavoriteItemIndex,
    setFavoriteItemListName,
    favoriteOpenEvent,
    removeFromFavoriteEvent,
    registrationRequestSms,
  };
  inject('dataLayer', context.$dataLayer);
};
