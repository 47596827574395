export const loginSuccessEvent = function () {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'login',
    eventCategory: 'Personal account',
    eventAction: 'Login',
    nonInteraction: 'false',
    properties: {
      method: 'phone',
    },
  };
};
