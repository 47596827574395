
import Vue, { PropType } from 'vue';
import ClickOutside from 'vue-click-outside';

import {
  MakeSearchFilters_makeSearch_category,
  MakeSearchFilters_makeSearch_categoryTree,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

const ROOT_CATEGORY_TITLE = 'Все категории';

export default Vue.extend({
  directives: { ClickOutside },
  components: {
    ButtonUi,
    IconUi,
  },
  inject: ['media'],
  props: {
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
    categoryTree: {
      type: Array as PropType<MakeSearchFilters_makeSearch_categoryTree[]>,
    },
    queryInput: {
      type: Object as PropType<MakeSearchQueryInput>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      activeCategoryId: undefined as number | undefined,
    };
  },
  computed: {
    concatCategories(): MakeSearchFilters_makeSearch_categoryTree[] {
      const flatArray = this.$store.getters['catalog/flattenCategories'];
      if (flatArray.length > 0 && this.categoryTree.length > 0) {
        const newArr: any[] = [];
        this.categoryTree.forEach((value) => {
          let matchingItem = flatArray.find((item) => item.id === value.category.id);
          if (matchingItem) {
            newArr.push({ category: matchingItem } as MakeSearchFilters_makeSearch_categoryTree);
          }
        });
        return newArr;
      }
      return [];
    },
    categoryTreeTop(): MakeSearchFilters_makeSearch_categoryTree[] {
      const rootCategory = this.concatCategories.find(
        (categoryItem) => categoryItem.category.title === ROOT_CATEGORY_TITLE,
      );

      return [
        ...this.concatCategories.filter(
          (categoryItem) => categoryItem.category.parent?.id === rootCategory?.category?.id,
        ),
      ].map((categoryItem) => ({
        ...categoryItem,
        children: this.concatCategories.filter((child) => child.category.parent?.id === categoryItem.category.id),
      }));
    },
    isNonEmpty(): boolean {
      return Boolean(this.activeCategoryId);
    },
  },
  created() {
    this.setInitialValue();
  },
  methods: {
    setInitialValue(): void {
      if (this.queryInput.categoryId) {
        this.activeCategoryId = Number(this.queryInput.categoryId);
      }
    },
    isActive(categoryItemId: number): boolean {
      return this.activeCategoryId === categoryItemId;
    },
    onSelect(categoryItemId: number): void {
      if (this.isActive(categoryItemId)) {
        this.onClear();
        return;
      }

      this.activeCategoryId = categoryItemId;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!this.media.isMobileOrTablet) {
        this.$emit('update-query-input', { categoryId: String(this.activeCategoryId) });
      }
      // this.$emit('update-query-input', { categoryId: String(this.activeCategoryId) });
    },
    submitFilters() {
      this.$emit('update-query-input', { categoryId: String(this.activeCategoryId) });
      this.onCloseFilters();
    },
    onClear() {
      this.activeCategoryId = undefined;
      this.$emit('update-query-input', { categoryId: undefined });
    },
    onToggle() {
      this.isOpen = !this.isOpen;
    },
    onClose() {
      this.isOpen = false;
    },
    onCloseFilters() {
      this.$emit('close');
    },
  },
});
