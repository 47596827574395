<template lang="pug">
div
  .ds-checkout-city
    dropdown-city-ds(
      v-if="value"
      :value="value"
      @select="selectCity"
      :is-error="!isValid && isShowWarnings"
  )
  template(v-if="customError && customError.length > 0")
    p.empty-address-error {{customError}}
  checkout-geo-position-ds
</template>
<script>
import { mapState } from 'vuex';
import IconUi from '~/components/common/icon';
import DropdownCityDs from '~/components/dropdown-city';
import { CheckoutFieldMixin } from '~/mixins/business/checkout';
import { StaticDataMixin } from '~/mixins/staticData';
import CheckoutGeoPositionDs from './checkout-geo-position.vue';
const EXCLUDED_REGIONS = ['Крым,', 'Севастополь', 'Байконур'];

export default {
  components: {
    DropdownCityDs,
    IconUi,
    CheckoutGeoPositionDs,
  },
  mixins: [CheckoutFieldMixin, StaticDataMixin],
  props: {
    label: {
      type: String,
      default: 'Укажите населенный пункт:',
    },
  },
  data() {
    return {
      field: 'city',
      cityCheck: [],
    };
  },
  computed: {
    ...mapState('checkout', {
      value: (state) => state.city,
    }),
    ...mapState('checkout', ['isShowWarnings']),
    isValid() {
      if (this.static.customCities.find((item) => item.includes(this.value.name) || item.includes(this.value.city))) {
        return true;
      }
      if (this.localCities.find((item) => item.includes(this.value.name) || item.includes(this.value.city))) {
        return true;
      }
      if (this.cityCheck.length === 0 || !this.value.name) {
        return false;
      } else if (this.cityCheck.findIndex((elem) => this.value.name.includes(elem.name)) === -1) {
        return false;
      } else if (this.customError && this.customError.length > 0) {
        return false;
      }
      return !!this.value;
    },

    customError() {
      if (!this.value) {
        return 'Заполните населенный пункт';
      }
      if (EXCLUDED_REGIONS.findIndex((elem) => this.value.name.includes(elem)) !== -1) {
        return 'Пока сюда не доставляем. Пожалуйста, выберете населенный пункт, куда доставка уже есть';
      }
      return '';
    },
  },
  watch: {
    value() {
      this.$store.commit('checkout/updateCheckStreets', []);
      this.$store.commit('checkout/updateAddress', {});
    },
  },
  methods: {
    selectCity(payload, concatCities) {
      if (payload && !Object.values(payload).length) {
        // ignore empty object
        return;
      }
      this.cityCheck = concatCities;
      this.$store.commit('checkout/updateCity', payload);
    },
  },
};
</script>
<style lang="stylus">
.empty-address-error
  color Red()
  margin-top 6px
  font-size 12px
.ds-checkout-city
  .label
    Text(BodyM)
    margin-bottom 8px
  .ds-dropdown-city
    width 485px
    +Media(Mobile, Tablet)
      width 100%
    +Media(Laptop)
      width 425px
    .ds-dropdown
      width 100%
      .ui-input
        display flex
        width 100%
        Input(Medium Outlined)
    .clear-btn
      right 28px
</style>
