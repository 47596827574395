<template lang="pug">
.ds-dropdown-city
  dropdown-ds(
    ref="dropdown"
    :list="concatCities"
    value-attribute="id"
    label-attribute="name"
    :value="value"
    @select="onSelect"
    @focus.native.capture="isActive = true"
    @blur.native.capture="blurCity()"
  )
    template(v-slot:option="props")
      div.option(v-html="toBold(props.option)")
    input-ui(
      :key="keyInput"
      ref="input"
      :value="value ? value.name : filteredText"
      placeholder="Введите населенный пункт"
      @input="onInput"
      @keypress.enter="onEnter"
      autocomplete="home city"
      v-bind="$attrs"
    )
      icon-ui.clear-btn(
        v-if="!isActive"
        name="navigation/search_sharp"
        fill="#C2C4CC"
        slot="after"
      )
      icon-ui.clear-btn(
        v-else-if="value"
        name="operations/close_line"
        :size="20"
        slot="after"
        @mousedown.native.prevent.stop="clear"
      )
</template>
<script>
import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import DropdownDs from '~/components/dropdown';
import LabeledInput from '~/components/labeled-input';
import { CitySearchMixin } from '~/modules/dadata-actions/mixins/city-search';
import { toBold } from '~/utils/intersections';
import { mapState } from 'vuex';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
import { StaticDataMixin } from '~/mixins/staticData';

const TOP_CITIES = [
  'Луганская Народная респ, г Луганск',
  'Донецкая Народная респ, г Донецк',
  'Донецкая Народная респ, г Мариуполь',
  'Луганская Народная респ, г Алчевск',
  'Донецкая Народная респ, г Харцызск',
  'Луганская Народная респ, г Краснодон',
  'Каменск-Шахтинский',
].map((name) => ({
  name,
  city: name,
  id: name,
}));

// const TOP_CITIES = [
//   'Луганская Народная респ, г Луганск',
//   'Донецкая Народная респ, г Донецк',
//   'Донецкая Народная респ, г Мариуполь',
//   'Луганская Народная респ, г Алчевск',
//   'Донецкая Народная респ, г Харцызск',
// ]

export default {
  components: {
    LabeledInput,
    InputUi,
    DropdownDs,
    IconUi,
  },
  mixins: [CitySearchMixin, StaticDataMixin],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    filteredText: '',
    currentCity: null,
    isActive: false,
    keyInput: 0,
    partnerCity: CITY_WITH_PARTNER_DP,
    topCities: TOP_CITIES,
  }),
  computed: {
    /**
     * @property checkCities - get cities from dadata suggestion
     * @property concatCities - get cities for dropdown list in case of input
     * @property keCheckCities - get cities for check list before upload dadata suggestions
     */
    ...mapState('deliveryMap', ['fiasCities']),
    checkCities() {
      return this.$store.state.checkout.checkCities;
    },
    concatCities() {
      const customCityList = this.static.customCities.map((name) => ({
        name,
        city: name,
        id: name,
      }));

      if (this.filteredText === '' || !this.allCities.length) {
        let list = [
          ...this.fiasCities.map((topCity) => {
            if (customCityList.find((city) => city.name == topCity.name)) {
              topCity.isCustom = true;
            }
            return {
              ...topCity,
              id: topCity.name,
            };
          }),
        ].filter((c) => !!this.value || c.name.toLowerCase().includes(this.filteredText.toLowerCase()));

        return list.slice(0, 30);
      }

      return [
        // ...customCityList.filter((customCity) => {
        //   const kshCity = this.keCities.find((keCity) => keCity.name === CITY_WITH_PARTNER_DP);
        //   customCity.deliveryPoints = JSON.parse(JSON.stringify(kshCity.deliveryPoints));
        //   customCity.cityId = JSON.parse(JSON.stringify(kshCity.id));
        //   customCity.isCustom = true;
        //   return customCity.name.toLowerCase().includes(this.filteredText.toLowerCase());
        // }),
        ...this.fiasCities
          .map((topCity) => {
            if (customCityList.find((city) => city.name == topCity.name)) {
              topCity.isCustom = true;
            }
            return {
              ...topCity,
              id: topCity.name,
            };
          })
          .filter((c) => c.name.toLowerCase().includes(this.filteredText.toLowerCase())),
        ...this.allCities
          .map((allCity) => {
            return {
              ...allCity,
              name: allCity.id,
            };
          })
          .filter((allCity) => {
            if (allCity && allCity.name && allCity.name.includes('Каменск-Шахтинский')) {
              return false;
            }
            const current = this.fiasCities.find((city) =>
              allCity.name ? allCity.name == city.name : allCity.city == city.name,
            );
            if (current) {
              return false;
            }
            return true;
          }),
      ];
    },
    isValid() {
      return !!this.value;
    },
  },
  watch: {
    async filteredText() {
      await this.updateCities();
    },
    isActive() {
      /**
       * Check value if blur
       * Hack, allow the onselect event to pass before the blur event
       */
      setTimeout(() => {
        if (
          !this.isActive &&
          this.currentCity &&
          this.currentCity.name !== this.filteredText &&
          this.allCities.length > 0 &&
          this.concatCities[0].name != this.filteredText
        ) {
          this.updateCity({ name: this.filteredText, id: this.filteredText, city: this.filteredText });
        } else if (!this.isActive && this.concatCities[0].name === this.filteredText) {
          this.updateCity(this.concatCities[0]);
        }
      }, 300);
    },
  },

  mounted() {
    if (this.$refs.dropdown) {
      this.$refs.dropdown.select(this.value);
    }
  },
  methods: {
    toBold(option) {
      return toBold(this.filteredText, option.name);
    },
    clear() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.select({ name: '', id: '', city: '' });
      }
      this.focusInput();
      this.filteredText = '';
    },
    blurCity() {
      this.isActive = false;
      setTimeout(() => {
        if (!this.isActive && this.concatCities[0]) {
          if (this.currentCity.name !== this.filteredText) {
            this.updateCity(this.concatCities[0]);
          }
        }
      }, 200);
    },
    blurDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.blur();
      }
    },
    focusInput() {
      // HACK: Need some delay before setting a focus to input.
      // This is caused by icon being clicked but still keeping a focus.
      // Probably, we have to refactor the way how icon behaves and remove a delay later.
      setTimeout(() => this.$refs.input.focus(), 100);
    },
    onInput(value) {
      this.filteredText = value;
    },

    async onSelect(option) {
      if (option) {
        this.filteredText = option.name;
        this.currentCity = option;
        this.updateCity(option);
      }
      this.blurDropdown();
      this.$store.dispatch('checkout/updateHiddenPoint', undefined);
      this.$store.dispatch('checkout/updateComment', '');
    },
    onEnter() {
      if (this.concatCities.length > 0 && this.$refs.dropdown) {
        this.$refs.dropdown.select(this.concatCities[0]);
      }
    },
    updateCity(payload) {
      if (
        this.fiasCities.find((city) => {
          return (
            (payload.name && payload.name == city.name) || (payload.city && payload.city.includes('Каменск-Шахтинский'))
          );
        })
      ) {
        this.$store.commit('checkout/updateDeliveryPoint', payload.deliveryPoints[0]);
      }
      this.$emit('select', payload, [...this.checkCities]);

      this.$nextTick(() => {
        // force update
        this.keyInput++;
      });
    },
  },
};
</script>
<style lang="stylus">
.ds-dropdown-city
  display flex
  align-items center
  .clear-btn
    position relative
    right 24px
    margin-right -24px
    cursor pointer
    &:hover
      path
        fill Gray(D32)
</style>
