<template lang="pug">
client-only
  .checkout-page
    error-message
    checkout-header
    guarantees-ui

    .page-content.ui-wrapper(v-if="localCities")
      main.checkout-main
        h1.checkout-heading Оформление заказа
        checkout-fragment(label="Населенный пункт получения")
          .ql-editor( v-html="dshopTexts?.additionalTexts[2]?.value")
          checkout-city
        checkout-delivery-info
        //- checkout-fragment(v-if="static.isShowOrderComment && delivery.type === 'DELIVERY_POINT'")
        //-   checkout-order-comment
        checkout-fragment(label="Получатель заказа")
          checkout-recipient
        checkout-fragment(label="Состав заказа")
          checkout-composition

      aside.sidebar
        .aside-wrapper
          button-ui.edit-order(v-if="!media.isMobileOrTablet" @click.native="$router.push({name: 'cart'})") Редактировать заказ
            icon-ui(name="operations/chevron-left_small-line" slot="before")
          checkout-order-summary
          checkout-delivery-summary
</template>

<script>
import ButtonUi from '~/components/common/button';
import GuaranteesUi from '~/components/guarantees';
import CheckoutFragment from './components/checkout-fragment';
import CheckoutHeader from './components/header';
import CheckoutDeliveryInfo from './components/checkout-delivery-info';
import CheckoutRecipient from './components/checkout-recipient';
import CheckoutComposition from './components/checkout-composition';
import CheckoutOrderSummary from './components/checkout-order-summary';
import CheckoutDeliverySummary from './components/checkout-delivery-summary';
import CheckoutOrderComment from './components/checkout-order-comment.vue';
import CheckoutCity from './components/checkout-city';
import IconUi from '~/components/common/icon';
import ErrorMessage from './components/error-message.vue';
import { CheckoutMixin } from '~/mixins/business/checkout';
import { StaticDataMixin } from '~/mixins/staticData';

export default {
  components: {
    CheckoutFragment,
    CheckoutHeader,
    CheckoutCity,
    CheckoutDeliveryInfo,
    CheckoutRecipient,
    CheckoutComposition,
    CheckoutOrderSummary,
    CheckoutDeliverySummary,
    CheckoutOrderComment,
    IconUi,
    ButtonUi,
    GuaranteesUi,
    ErrorMessage,
  },

  mixins: [CheckoutMixin, StaticDataMixin],
  inject: ['media'],
  layout: 'headerless',

  async mounted() {
    await this.getPoints();
    await this.getCustomCities();
    const query = this.$route.query;
    if (query.OrderId && query.ok === 'true') {
      return this.$router.replace({
        name: 'checkout-success',
        params: { orderId: query.OrderId },
      });
    }
  },
};
</script>

<style lang="stylus">
.checkout-page
  .ui-input-grid
    +Media(Laptop)
      max-width 100%
    +Media(Desktop)
      max-width 425px
    +Media(DesktopMax)
      max-width 485px

  .ds-guarantees
    +Media(Mobile, Tablet)
      display none

  .page-content
    padding-top 16px
    +Media(Laptop, Desktop, DesktopMax)
      display grid

    +Media(Laptop)
      grid-template-columns 1fr 340px
      grid-column-gap 48px
    +Media(Desktop)
      grid-template-columns 1fr 328px
      grid-column-gap 60px
    +Media(DesktopMax)
      grid-template-columns 1fr 378px
      grid-column-gap 60px

    .checkout-heading
      Text(TitleM Medium Short Secondary)
      color TextPrimary()
      margin-top 32px
      +Media(Mobile,Tablet)
        margin-top 16px

  aside.sidebar
    .aside-wrapper
      +Media(Laptop, Desktop, DesktopMax)
        position sticky
        top 20px
    .edit-order
      Button()
      Text(CaptionM)
      display flex
      margin 62.5px 0 24.5px auto
    +Media(Mobile, Tablet)
      margin-top 32px
</style>
