
import Vue, { PropType } from 'vue';

import {
  MakeSearchFilters_makeSearch_category,
  MakeSearchFilters_makeSearch_categoryTree,
  MakeSearchFilters_makeSearch_facets,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import SearchSortDs from '~/modules/search/components/filters/search-sort.vue';
import SearchFiltersDs from '~/modules/search/components/filters/search-filters.vue';

export default Vue.extend({
  components: {
    SearchFiltersDs,
    SearchSortDs,
  },

  props: {
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    facets: {
      type: Array as PropType<MakeSearchFilters_makeSearch_facets[] | null>,
      default: null,
    },
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
    categoryTree: {
      type: Array as PropType<MakeSearchFilters_makeSearch_categoryTree[]>,
    },
    queryInput: {
      type: Object as PropType<MakeSearchQueryInput>,
      required: true,
    },
  },

  mounted() {
    this.observeOptions();
  },

  methods: {
    observeOptions() {
      const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('sticky', e.intersectionRatio < 1), {
        threshold: [1],
        rootMargin: '0px 100px 0px 100px',
      });
      observer.observe(this.$el as HTMLElement);
    },
  },
});
