<template lang="pug">
yandex-map(
  v-if="currentBtn"
  class="delivery-point-map"
  :settings="settings"
  :key="key"
  :coords="coords(currentBtn, media.isMobileOrTablet)"
  :zoom="16"
  :show-all-markers="updateMap && points && points.length > 1"
  :controls="[]"
  @click="close"
  )
    ymap-marker(
      v-for="(point) in points"
      :key="point.id"
      :markerId="point.id"
      :coords="coords(point)"
      :icon="marker(point)"
      @click="clickHandler(point)"
    )
    .popup-map(v-if="isShow")
      icon-ui.close(name="operations/close_line" @click.native="close")
      .address-card
        .title Пункт выдачи заказов {{ mobilePoint.isPost ? 'в почтовом отделении' : 'ПСБ маркет' }}
        .info
          icon-ui(name="navigation/city_line" :size="20")
          span {{ mobilePoint.value }}
        .info(v-if="mobilePoint.additionalField")
          icon-ui(name="navigation/faq_line" :size="20")
          span.additional
            div(v-for="field in additionalFields" :key="additionalFields + Math.random()") {{ field }}
        .info
          icon-ui(name="navigation/clock_sharp" :size="20")
          span {{ mobilePoint.workingTime }}
        .info
          icon-ui(name="navigation/payed_info" :size="20")
          span Доставка: {{mobilePoint.isPayed ? '200 ₽' : 'бесплатно'}}
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { mapState } from 'vuex';
import { settings } from '~/modules/map/yandex';
import MarkerImage from '~/static/icons/map-markers/pvz.svg';
import PostMarker from '~/static/icons/map-markers/post.svg';
import MarkerImageSelected from '~/static/icons/map-markers/pvz_selected.svg';
import PostMarkerSelected from '~/static/icons/map-markers/post_selected.svg';
import { StaticDataMixin } from '~/mixins/staticData';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
import IconUi from '~/components/common/icon';
export default {
  // @todo common mixin extend mixin/map
  components: {
    yandexMap,
    ymapMarker,
    IconUi,
  },
  mixins: [StaticDataMixin],
  inject: ['media'],
  data: () => ({
    settings,
    currentBtn: null,
    currentMap: null,
    key: 0,
    isShow: false,
    mobilePoint: null,
    updateMap: true,
  }),
  computed: {
    ...mapState('deliveryMap', {
      city: (state) => state.deliveryMapCity,
      cities: (state) => state.fiasCities,
    }),

    points() {
      const current = this.cities.find((point) =>
        this.city.name ? this.city.name == point.city : this.city.city == point.city,
      );
      const partner = this.city.city
        ? this.city.city.includes(CITY_WITH_PARTNER_DP)
        : this.city.name.includes(CITY_WITH_PARTNER_DP);
      if (current) {
        return current.points;
      }
      if (partner) {
        return this.cities.reduce((acc, obj) => acc.concat(obj.points), []);
      }
      return [];
    },
    additionalFields() {
      return this.mobilePoint && this.mobilePoint.additionalField
        ? this.mobilePoint.additionalField.split('\n').filter((val) => val.length > 0)
        : null;
    },
  },
  watch: {
    city: {
      handler() {
        this.isShow = false;
        this.key++;
        if (this.points.length) {
          this.currentBtn = this.points[0];
          this.updateMap = true;
        }
        if (this.points.length === 1) {
          this.$eventManager.emit('show-point', this.points[0]);
          if (this.media.isMobileOrTablet) {
            this.mobilePoint = this.points[0];
            this.isShow = true;
          }
        }
      },
      immediate: true,
    },
    points: {
      handler() {
        this.isShow = false;
        this.key++;
        if (this.points.length) {
          this.currentBtn = this.points[0];
          this.updateMap = true;
        }
        if (this.points.length === 1) {
          this.$eventManager.emit('show-point', this.points[0]);
          if (this.media.isMobileOrTablet) {
            this.mobilePoint = this.points[0];
            this.isShow = true;
          }
        }
      },
    },
  },
  beforeDestroy() {
    this.$eventManager.off('select-point', this.showPoint);
  },
  // eslint-disable-next-line vue/order-in-components
  async fetch() {
    await this.getPoints();
  },
  mounted() {
    this.$eventManager.on('select-point', this.showPoint);
    if (this.points.length && !this.currentBtn) {
      this.currentBtn = this.points[0];
    }
    if (this.points.length === 1) {
      this.$eventManager.emit('show-point', this.points[0]);
      if (this.media.isMobileOrTablet) {
        this.mobilePoint = this.points[0];
        this.isShow = true;
      }
    }
  },
  methods: {
    marker(point) {
      let image;
      if (this.points.length === 1) {
        image = point.isPost ? PostMarkerSelected : MarkerImageSelected;
      } else if (this.currentBtn.id === point.id && !this.updateMap) {
        image = point.isPost ? PostMarkerSelected : MarkerImageSelected;
      } else {
        image = point.isPost ? PostMarker : MarkerImage;
      }
      return {
        layout: 'default#image',
        imageSize: [55, 55],
        imageHref: image,
      };
    },
    close() {
      this.isShow = false;
    },
    clickHandler(point) {
      this.updateMap = false;
      if (this.media.isMobileOrTablet) {
        this.mobilePoint = point;
        this.isShow = true;
      }
      this.currentBtn = point;
      this.$eventManager.emit('show-point', point);
    },

    coords(point, mobile) {
      if (mobile) {
        return [point.latitude - 0.001, point.longitude];
      }
      return [point.latitude, point.longitude];
    },
    showPoint(point) {
      this.updateMap = false;
      this.currentBtn = point;
    },
  },
};
</script>

<style lang="stylus" scoped>
.delivery-point-map
  position relative
  height: 664px

  +Media(Mobile, Tablet)
    height calc(100vh - 360px)

  .popup-map
    background #fff
    position fixed
    bottom 0
    right 0
    left 0
    width 100%
    z-index: 3;

    .close
      position absolute
      right 12px
      top 12px
      cursor pointer
    .ds-checkout-delivery-point-item
      border-bottom none
      margin 16px 16px 0
    .choose
      Button(Medium Primary)
      Text(CaptionM)
      width calc(100% - 32px)
      margin 16px

    .address-card
      box-sizing: border-box
      padding 20px 24px
      display flex
      flex-direction column
      gap 8px
      border-bottom 1px solid #E4E6EB

      &:hover
        background: #F2F4F7;
        cursor pointer
        +Media(Mobile, Tablet)
          background: white;
      &.active
        background: #F2F4F7;
        +Media(Mobile, Tablet)
          background: white;
      .title
        font-size 14px
        font-weight 500
        line-height 18px

      .info
        display flex
        gap 8px
        font-size 14px
        line-height 20px
        .ui-icon
          min-width 20px
        .additional
          display: flex
          flex-direction column
          gap 8px
      .link
        font-size 12px
        line-height 18px
        display none
        color #2C2D84
        align-items center
        +Media(Mobile, Tablet)
          display flex
</style>
