export const phoneTransform = (value) => {
  if (!value) {
    return '';
  }
  if (value.startsWith('8')) {
    return `+7${value.substring(1)}`;
  }
  if (value.startsWith('+7') || value.startsWith('7')) {
    return value;
  }
  const truePhone = value.replace('+7', '').replace(/[^0-9]/g, '');
  return truePhone.slice(-10);
};
