
import Vue, { PropType } from 'vue';
import { mapState, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { search, searchFilters } from '~/apollo-api/search';
import {
  FilterInputType,
  MakeSearch_makeSearch,
  MakeSearchFilters_makeSearch,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import { isEqualArray } from '~/modules/common/equal-array';
import { throttle } from '~/modules/common/throttle';

import SpinnerUi from '~/components/spinner.vue';

import SearchBreadcrumbsDs from '~/modules/search/components/search-breadcrumbs.vue';
import SearchCategoryDs from '~/modules/search/components/search-category.vue';
import SearchFormDs from '~/modules/search/components/search-form.vue';
import SearchProductsDs from '~/modules/search/components/search-products.vue';

import { DEFAULT_QUERY_INPUT, DEFAULT_PAGINATION, DEFAULT_FILTERS_PAGINATION } from '~/modules/search/constants';
import { getFiltersFromUrl } from '~/modules/search/utils/get-filters-from-url';
import AddedToFavorite from '~/components/popups/add-favorite-popup.vue';
import { add, remove } from '~/apollo-api/favorite';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import { getProduct } from '~/apollo-api/getProduct';
import { DESC_SORT_VALUE } from '~/modules/search/constants/sort-data';
import { StaticDataMixin } from '~/mixins/staticData';
import AdultNotification from './adult-notification.vue';

const THROTTLE_TIME = 600;
const LOADING_DISTANCE = 300;

export default Vue.extend({
  components: {
    InfiniteLoading,
    SpinnerUi,
    SearchFormDs,
    SearchProductsDs,
    SearchBreadcrumbsDs,
    SearchCategoryDs,
    AddedToFavorite,
    AdultNotification,
  },
  mixins: [StaticDataMixin],

  props: {
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    additionalQueryInput: {
      type: Object as PropType<Partial<MakeSearchQueryInput>>,
      default: () => {},
    },
    searchType: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return {
      loadingDistance: LOADING_DISTANCE,
      queryInput: DEFAULT_QUERY_INPUT,
      filters: null as MakeSearchFilters_makeSearch | null,
      data: null as MakeSearch_makeSearch | null,
      // eslint-disable-next-line @typescript-eslint/ban-types
      throttledInfiniteSearch: undefined as Function | undefined,
      isNeedFullUpdate: false,
      searchPermission: false,
    };
  },

  computed: {
    askAdult() {
      return this.$store.state.main.askAdult;
    },
    isCatalog() {
      return this.searchType === 'catalog';
    },
    cardsFromCache() {
      return this.$store.getters['cache/cards'];
    },
    searchQueryFromCache() {
      return this.$store.getters['cache/query'];
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.queryInput = {
          ...this.queryInput,
          pagination: {
            ...this.queryInput.pagination,
            offset: 0,
          },
        };

        if (this.data) {
          this.resetInfiniteSearch();
        }
      },
    },
    queryInput: {
      deep: true,
      handler(newValue, oldValue) {
        this.updateQueryRoute(newValue, oldValue);
      },
    },
    additionalQueryInput: {
      deep: true,
      handler() {
        this.resetInfiniteSearch();
      },
    },
  },

  async created() {
    this.setInitialQueryInput();
    this.throttledInfiniteSearch = throttle(this.onInfiniteSearch, THROTTLE_TIME);
    if (this.cardsFromCache.items.length) {
      this.data = this.cardsFromCache;
      this.queryInput = this.searchQueryFromCache;
      await this.getSearchFilters();
    }
  },

  mounted() {
    if (this.$route.name === 'catalog') {
      this.searchPermission = !!(this.additionalQueryInput && this.additionalQueryInput.categoryId);
    } else {
      this.searchPermission = true;
    }
    const isSortByDesc = !!this.isDescPriceSort();
    if (isSortByDesc) {
      this.queryInput.sort = DESC_SORT_VALUE;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  },

  methods: {
    ...mapActions({
      setSearchCards: 'cache/setSearchCards',
      setSearchQuery: 'cache/setSearchQuery',
    }),
    resetInfiniteSearch() {
      if (this.data) {
        this.data = {
          ...this.data,
          items: [],
        };
      }
      this.isNeedFullUpdate = true;
      (this.$refs.infinite as any).stateChanger.reset();
    },
    isDescPriceSort() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const categories = this.$store.getters['catalog/flattenSortByDesc'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const descCatalogList = this.static.DescCatalogList;
      const activeCategoryId = this.additionalQueryInput
        ? this.additionalQueryInput.categoryId
          ? this.additionalQueryInput.categoryId
          : null
        : null;
      const searchText = this.additionalQueryInput
        ? this.additionalQueryInput.text
          ? this.additionalQueryInput.text.toLowerCase()
          : null
        : null;
      if (activeCategoryId && categories) {
        return categories.find((category) => category.id == activeCategoryId);
      } else if (searchText && descCatalogList && descCatalogList.length) {
        return descCatalogList.find((item) => searchText.includes(item));
      }
      return false;
    },
    async onInfiniteSearch($state) {
      if (!this.$isServer && this.searchPermission) {
        try {
          await this.getSearchFilters();
          const response = await search({
            ...this.queryInput,
            ...this.additionalQueryInput,
          });

          if (!response) {
            this.data = null;
            $state.complete();
            return;
          }

          if (response.items?.length) {
            this.queryInput = {
              ...this.queryInput,
              pagination: {
                ...this.queryInput.pagination,
                offset: this.queryInput.pagination.offset + this.queryInput.pagination.limit,
              },
            };
            this.data = {
              ...response,
              items:
                !this.isNeedFullUpdate && this.data?.items ? [...this.data.items, ...response.items] : response.items,
            };
            this.isNeedFullUpdate = false;
            $state.loaded();
          } else {
            this.data = {
              ...response,
              items: !this.isNeedFullUpdate && this.data?.items ? this.data.items : response.items,
            };
            this.isNeedFullUpdate = false;
            $state.complete();
          }

          if (this.$route.name === 'catalog' && !(this.additionalQueryInput && this.additionalQueryInput.categoryId)) {
            $state.complete();
          }

          this.setSearchCards(JSON.parse(JSON.stringify(this.data)));
          this.setSearchQuery(this.queryInput);
        } catch (e) {
          $state.error();
          throw e;
        }
      }
    },
    setInitialQueryInput() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { query } = this.$route;
      const queryInput = {};
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('filters')) {
        const { filters } = query;
        console.log([filters]);
        if (Array.isArray(filters)) {
          queryInput['filters'] = filters.map((filter) => getFiltersFromUrl(filter!));
        } else {
          queryInput['filters'] = [getFiltersFromUrl(filters)];
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('sort')) {
        const { sort } = query;
        if (Array.isArray(sort)) {
          queryInput['sort'] = sort[0];
        } else {
          queryInput['sort'] = sort;
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('categoryId')) {
        queryInput['categoryId'] = query.categoryId;
      }

      this.updateQueryInput(queryInput);
    },
    updateQueryInput(newValue) {
      const newQueryInput = {
        ...this.queryInput,
        pagination: DEFAULT_PAGINATION,
      };

      // eslint-disable-next-line no-prototype-builtins
      if (newValue.hasOwnProperty('filterType')) {
        this.queryInput = {
          ...newQueryInput,
          filters: [...this.queryInput.filters.filter((value) => value.id !== newValue.id), newValue],
        };
        return;
      }

      this.queryInput = {
        ...newQueryInput,
        ...newValue,
      };
    },
    updateQueryRoute(newValue: MakeSearchQueryInput, oldValue: MakeSearchQueryInput) {
      const params = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...this.$route.params,
        toPosition: {
          y: Math.min(window.scrollY, this.$refs.main ? (this.$refs.main as HTMLElement).offsetTop : window.scrollY),
        },
      } as any;

      if (!isEqualArray(newValue.filters, oldValue.filters)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            filters: newValue.filters.map((filter) => {
              if (filter.filterType === FilterInputType.RANGE) {
                return `${filter.filterType}~${filter.id}:${filter.range!.min}~${filter.range!.max}`;
              }
              return '';
            }),
          },
        });
      }

      if (newValue.sort !== oldValue.sort) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            sort: newValue.sort,
          },
        });
      }

      if (newValue.categoryId !== oldValue.categoryId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            categoryId: newValue.categoryId,
          },
        });
      }
    },
    async getSearchFilters() {
      try {
        const response = await searchFilters(
          {
            ...this.queryInput,
            ...this.additionalQueryInput,
            pagination: DEFAULT_FILTERS_PAGINATION,
          },
          this.isCatalog,
        );

        if (!response) {
          this.filters = null;
          return;
        }

        this.filters = response;
      } catch (error) {
        console.error(error);
      }
    },
    showAddedToFavoritePopup(added) {
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY,
        component: AddedToFavorite,
        propsData: {
          added,
        },
      });
    },
    async favoriteChange(ids) {
      const product = await getProduct(ids[1]);
      const sku = this.data?.items?.find((_) => _.catalogCard.id === ids[0]);
      if (sku && sku.catalogCard.favorite) {
        await remove([ids[0]]);
        this.showAddedToFavoritePopup(false);
        this.$store.dispatch('main/updateProductFavoriteStatus', ids);
        this.$store.dispatch('viewedProducts/updateProduct', ids);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$gtm.push(this.$dataLayer.removeFromFavoriteEvent(product, product.skuList[0]));
      } else {
        await add([ids[0]]);
        this.showAddedToFavoritePopup(true);
        this.$store.dispatch('main/updateProductFavoriteStatus', ids);
        this.$store.dispatch('viewedProducts/updateProduct', ids);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$gtm.push(this.$dataLayer.addToFavoriteEvent(product, product.skuList[0]));
      }
      if (sku) {
        // console.log('itWorks');
        // this.$store.dispatch('main/updateProductFavoriteStatus', [ids[1], ids[0], !ids[2]]);
        // this.$store.dispatch('viewedProducts/updateProduct', [ids[1], ids[0], !ids[2]]);
        ///need fix
        sku.catalogCard.favorite = !sku.catalogCard.favorite;
      }
    },
  },
});
